<template>
  <mobile-float-menu header>
    <template v-slot:content>
      <LoadPage :pages='pages' />
    </template>
  </mobile-float-menu>
</template>

<script>
  import LoadPage from "@/components/LoadPage.vue";
  import MobileFloatMenu from "@/components/MobileFloatMenu";
  export default {
    components: {
      LoadPage,
      MobileFloatMenu,
    },
    data() {
      return {
        pages: {
          es: '6001ce1b9c5ab000172a725c',
          en: '6001be699c5ab000172a725a',
        }
      }
    },
  }
</script>